export const deviceSize = {
  xs: 425,
  sm: 768,
  md: 1024,
  lg: 1440,
};

export const layoutConstant = {
  topbarHeight: 40,
  grocerySidenavWidth: 280,
  containerWidth: 1200,
  mobileNavHeight: 64,
  headerHeight: 80,
  mobileHeaderHeight: 200,
};

// Auction
export const AUCTION_FEE_PRODUCT_ID = "9000868";
export const AUCTION_FEE_DUTY_PRODUCT_ID = "1s0s4s9s2s2s9";

// * Redis keys
// ! DO NOT TOUCH BELOW CONSTANTS
// Categories
export const CACHE_EXPIRES_IN_ONE_DAY = 5400; // 1.5 hours
export const CACHE_EXPIRES_IN_EIGHT_HOURS = 28800; // 8 hours
export const CACHE_EXPIRES_IN_THREE_DAYS = 86400; // 1 day
export const CACHE_EXPIRES_IN_ONE_WEEK = 604800; // 1 week
export const CACHE_EXPIRES_IN_SIX_HOURS = 21600; // 6 hours

export const CACHE_ROOT_CATEGORIES = "root-categories";
export const CACHE_MENU_CATEGORIES = "menu-categories";
export const CACHE_BANNERS = "root-banners";
export const CACHE_BRANDS = "brands";
export const CACHE_SPECIAL_PRODUCTS = "special-products";
export const CACHE_VALENTINE_PRODUCTS = "valentine-products";
export const CACHE_PRODUCTS_WITH_GIFT = "products-with-gift";
export const CACHE_PRODUCTS_FASHION = "products-fashion";
export const CACHE_ELECTRONICS_PRODUCTS = "ns-elec-products";
export const CACHE_HOMESTYLE_PRODUCTS = "ns-homestyle-products";
export const CACHE_COSMETICS_PRODUCTS = "ns-cos-products";
export const CACHE_BESTSELLER_PRODUCTS = "ns-book-products";
export const CACHE_HOME_BLOGS = "home-blogs";
export const CACHE_HOT_DEALS = "hot-deals";
export const CACHE_SEARCH_DEFAULT_RESULTS = "search-default-results";
// page
export const CACHE_PAGE_FEATURED = "page-featured";
export const CACHE_PAGE_BAIGUULLAGA = "page-baiguullaga";
export const CACHE_PAGE_AYLAL = "page-aylal";
export const CACHE_PAGE_ZUSLAN = "page-zuslan";
export const CACHE_PAGE_SALES = "page-sales";
export const CACHE_PAYMENT_TIMEOUT = 18000; // 5 hours
export const CACHE_NOTIFICATIONS = "notifications";
export const CACHE_CMS = "cms-cache";
export const CACHE_CMS_SEARCH = "cms-cache-search";
export const CACHE_CMS_ONBOARD = "cms-cache-onboard";
export const CACHE_CMS_TEST_HOME = "cms-cache-test-home";
// CDN
export const CDN_MEDIA = "https://cdn5.nomin.mn/media/";
export const BACKEND_URL = "https://backend.nomin.mn";
// payment states
export const PAYMENT_STATE_PENDING = "pending";
export const PAYMENT_STATE_PAID = "paid";
export const PAYMENT_STATE_ERROR = "error";

// * Session keys
// ! DO NOT TOUCH BELOW CONSTANTS
export const SESSION_CART = "ns-c";
export const SESSION_CART_ID = "ns-ci";
export const SESSION_CUSTOMER_MAGENTO_ACCESS_TOKEN = "ns-cmt";
export const SESSION_CUSTOMER_BONUS_NUMBER = "ns-cbn";
export const SESSION_CUSTOMER_LOYALTY_TOKEN = "ns-clt";
export const SESSION_CUSTOMER_AVATAR_URL = "ns-cau";
export const SESSION_CUSTOMER_FULLNAME = "ns-cfn";
export const SESSION_CUSTOMER_PHONE = "ns-cp";
export const SESSION_CUSTOMER_WISHLIST_ID = "ns-cwi";
export const SESSION_FINDCUSTOMER_EMAIL = "ns-fce";
export const SESSION_FINDCUSTOMER_PHONE = "ns-fcp";
export const SESSION_IS_BONUS_APPLIED = "ns-cb";
export const SESSION_ORDER_NUMBER = "ns-on";
export const SESSION_ORDER_AMOUNT = "ns-oa";
export const SESSION_AUCTION_ID = "au-id";
export const SESSION_SHIPPING_METHOD = "sh-me";
export const SESSION_BRANCH_NAME = "br-nm";

export const TOAST_TYPE_SUCCESS = "ts";
export const TOAST_TYPE_WARNING = "tw";
export const TOAST_TYPE_INFO = "ti";
export const TOAST_TYPE_ERROR = "te";

// * PRODUCTS PAGINATION
export const PRODUCTS_PER_PAGE = 30;
export const PRODUCT_SALABLE_MIN_VALUE = 0;
export const PRODUCTS_SORTING_OPTIONS = [
  { label: "Энгийн", value: "position-desc" },
  { label: "Шинэ нь эхэндээ", value: "news_from_date-asc" },
  { label: "Хуучин нь эхэндээ", value: "news_from_date-desc" },
  { label: "Үнэ өсөхөөр", value: "price-asc" },
  { label: "Үнэ буурахаар", value: "price-desc" },
  { label: "Хямдралын хувиар", value: "special_price_percent-desc" },
  { label: "Шинэ хямдрал", value: "special_to_date-desc" },
];
export const PRODUCTS_PAGE_SIZES = [
  { label: "30", value: "30" },
  { label: "60", value: "60" },
  { label: "90", value: "90" },
  { label: "120", value: "120" },
];

// * PRODUCTS PAGINATION
export const ROOT_CATEGORY_ID = 2;
export const PAGE_CATEGORY_FILTERS_SKIP_KEYS = [
  "page",
  "price",
  "q",
  "sb",
  "ps",
  "fbclid",
  "utm_campaign",
  "_se",
  "utm_medium",
  "utm_source",
  "mcp_token",
  "_sc",
  "message"
];

// * PAYMENT METHODS
export const PAYMENT_METHODS = [
  "khanbankpayment",
  "accountpayment",
  "golomtbankpayment",
  "socialpaypayment",
  "qpaypayment",
  "lendpayment",
  "passpaypayment",
  "storepaypayment",
  "ominipaypayment",
  "mostpaypayment",
  "pocketpayment",
  "lendpaypayment",
  "tokipayment",
  "arigpayment",
];
